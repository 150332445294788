<template>
  <div class="wrapper" :class="isCalendarTlp ? 'seminars-tpl' : ''" >
    <HeaderCalendar :user="user" />
    <main class="main">
      <Sidebar v-if="!isCalendarTlp"/>
      <router-view />
    </main>
    <Footer v-if="isCalendarTlp"/>
    <FooterSeminars v-else/>
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import HeaderCalendar from "@/components/HeaderCalendar";
import FooterSeminars from "@/components/FooterSeminars";
import ApiService from "@/services/api.service";
import {TokenService} from "@/services/token.service";
import {eventBus} from "@/main";
export default {
  name: 'App',
  data() {
    return {
      menu: null,
      user: null
    }
  },
  components: {
    FooterSeminars,
    HeaderCalendar,
    Footer,
    Sidebar
  },
  computed: {
    isCalendarTlp() {
      return this.$route.name === 'Calendar'
    }
  },
  async mounted() {
    ApiService.get('https://myexport.exportcenter.ru/api/menu?lang=', {withCredentials: false}).then(res => {
      this.menu = res.data
    })
    if(process.env.VUE_APP_DEMO == 0) {
      await ApiService.post('https://' + process.env.VUE_APP_LK_API + '/sso/oauth2/access_token', {
        'client_id': 'export-school',
        'client_secret': 'k7f2mgfaV',
        'realm': '/customer',
        'grant_type': 'urn:roox:params:oauth:grant-type:m2m',
        'service': 'dispatcher',
        'response_type': 'token cookie'
      }, true).then(() => {
        ApiService
            .get('https://' + process.env.VUE_APP_LK_API + '/oauth2-consumer/tokens/@current')
            .then((res) => {
              TokenService.setUser(res.data.attributes.displayName);
              this.user = TokenService.getUser();
              ApiService.post('ow/users', res.data.attributes, false).then(res => {
                TokenService.setExportUser(res.data.oauth.access_token)
                TokenService.setExportUserId(res.data.user)
                eventBus.$emit('auth')
              })
            }).catch(() => {
          window.location.href = 'https://' + process.env.VUE_APP_LK_API + '/ru/login'
        })
      }).catch(() => {
        window.location.href = 'https://' + process.env.VUE_APP_LK_API + '/ru/login'
      })
    } else {
      TokenService.setExportUser('MjE3OTNhODY0MjhiNTg5Y2YyZGI4OTQ3ODI5ZjBkNDJmNDhjZGJjZjk5OTNhYzdkNzY3NWM1ZDEyMjA3NTczNQ')
      TokenService.setExportUserId(108303)
      eventBus.$emit('auth')
    }
  }
}
</script>

<style lang="scss">
@import "assets/style/main";
.opacity {
  opacity: 0;
  margin-top: -50px;
  float: left;
}
.spinner-border {
  margin: 0 -1rem;
}
</style>
